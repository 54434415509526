// encapsulate to prevent name collisions
(function ($, document, window) {
	// the entry point of your JavaScript
	// here you will put all your initialization code
	var init = function () {
		
			//Initializing
			ripple_effect();
			$.slidebars();
			$('.selectpicker').selectpicker();
			$('.nav-tabs a').click(function (e) {
			  e.preventDefault();
			  $(this).tab('show');
			});			
  			$('[data-toggle="tooltip"]').tooltip({
  				trigger : 'hover',
  				'delay': { show: 500 }
  			});
  			$('[data-toggle="tooltip-html"]').tooltip({ 
  				 trigger : 'hover',
  				 html:true,
  				 'delay': { show: 500, hide:1000 }
  			});
			$(".bs-switch").bootstrapSwitch();
			$(".bs-slider").slider({
				//tooltip: 'always'
			});
			
		  	$('input.icheck').iCheck({
		    	checkboxClass: 'icheckbox_flat-green',
		    	radioClass: 'iradio_flat-green',
		    	disabledCheckboxClass: 'icheckbox_flat-aero',
    			disabledRadioClass: 'iradio_flat-aero'
		  	});

		};
		
	//make sure jQuery exists and is loaded
	$(function () {
		//make sure the dom is loaded
		$(document).ready(init);
	});
	
}(this.jQuery, document, this));




 $(document).on('mouseenter', ".ellipsis", function () {
 	
	// 	Ellipsis Effect
	// 	=====================================================
	//	http://jsfiddle.net/chadkuehn/nu5zk/
	// 	Adds a ellipsis + tooltip effect on a block element
	// 	with the .ellipsis class  
	 	
     var $this = $(this);
     if (this.offsetWidth < this.scrollWidth && !$this.attr('title')) {
         $this.tooltip({
             title: $this.text()
         });
         $this.tooltip('show');
     }
 });
 
 

function ripple_effect(){
	
	// 	Ripple Effect
	// 	=====================================================
	//	http://codepen.io/carmination/pen/yNKZWO
	// 	Adds a visual effect when an element
	// 	with the class .btn is clicked. 
		
	$(document).on('click', '.btn', function(e) {
	  var $rippleElement = $('<span class="ripple-effect"/>'),
	    $buttonElement = $(this),
	    btnOffset = $buttonElement.offset(),
	    xPos = e.pageX - btnOffset.left,
	    yPos = e.pageY - btnOffset.top,
	    size = parseInt(Math.min($buttonElement.height(), $buttonElement.width()) * 0.5),
	    animateSize = parseInt(Math.max($buttonElement.width(), $buttonElement.height()) * Math.PI);
	
	  $rippleElement.css({ top: yPos, left: xPos, width: size, height: size, backgroundColor: $buttonElement.data("btn-color") })
	    .appendTo($buttonElement)
	    .animate({
	      width: animateSize,
	      height: animateSize,
	      opacity: 0
	    }, 700, function() {
	      $(this).remove();
	    });
	});
	
	$(document).on('click', '.list-group-item', function(e) {
	  var $rippleElement = $('<span class="ripple-effect"/>'),
	    $buttonElement = $(this),
	    btnOffset = $buttonElement.offset(),
	    xPos = e.pageX - btnOffset.left,
	    yPos = e.pageY - btnOffset.top,
	    size = parseInt(Math.min($buttonElement.height(), $buttonElement.width()) * 0.5),
	    animateSize = parseInt(Math.max($buttonElement.width(), $buttonElement.height()) * Math.PI);
	
	  $rippleElement.css({ top: yPos, left: xPos, width: size, height: size, backgroundColor: $buttonElement.data("btn-color") })
	    .appendTo($buttonElement)
	    .animate({
	      width: animateSize,
	      height: animateSize,
	      opacity: 0
	    }, 700, function() {
	      $(this).remove();
	    });
	});	
	
	$(document).on('click', '.nav li a', function(e) {
	  var $rippleElement = $('<span class="ripple-effect"/>'),
	    $buttonElement = $(this),
	    btnOffset = $buttonElement.offset(),
	    xPos = e.pageX - btnOffset.left,
	    yPos = e.pageY - btnOffset.top,
	    size = parseInt(Math.min($buttonElement.height(), $buttonElement.width()) * 0.5),
	    animateSize = parseInt(Math.max($buttonElement.width(), $buttonElement.height()) * Math.PI);
	
	  $rippleElement.css({ top: yPos, left: xPos, width: size, height: size, backgroundColor: $buttonElement.data("btn-color") })
	    .appendTo($buttonElement)
	    .animate({
	      width: animateSize,
	      height: animateSize,
	      opacity: 0
	    }, 700, function() {
	      $(this).remove();
	    });
	});		
};


// 	Visibility/Animation Functions
// 	=====================================================

function showObject(object){
    	loadingAnimation(400);
    	$(object).fadeIn(400, "linear");
}

function hideObject(object){
    	loadingAnimation(100);
    	$(object).fadeOut(100, "linear");
}

function toggleVisibility(object){
	if ( $(object).css('display') == 'none' ){
    	showObject(object);
	} else {
    	hideObject(object);
	}		
}

function toggleLeft(object){
    $(object).show("slide", { direction: "left" }, 1000);
}

function loadingAnimation(time){
	$('.loading-animation').fadeIn();
	setTimeout(function() { $('.loading-animation').fadeOut(); }, time);				
}
    
// 	Collapse
// 	=====================================================
// 	Changes the arrow icon up or down depending
//  on the collapse current status
    
	$(document).ready(function () {
	 $('.collapse')
	     .on('shown.bs.collapse', function() {
	         $(this)
	             .parent()
	             .find(".fa-caret-down")
	             .removeClass("fa-caret-down")
	             .addClass("fa-caret-up");
	         })
	     .on('hidden.bs.collapse', function() {
	         $(this)
	             .parent()
	             .find(".fa-caret-up")
	             .removeClass("fa-caret-up")
	             .addClass("fa-caret-down");
	         });
	     });


// 	Updating Filter values + showing loading animation
// 	=====================================================

	/* Updates the value of the text usually shown below filters
	 * based on the value and the name of a given form element.
	 * It also displays the loading animation briefly
	 */

	function updateFilterValue(formName, time){
	
		$("input[name="+ formName + "]").change(function(){
			// Show loading-animation
			loadingAnimation(time);
			// Display Value
	    	$("#display-"+ formName).text($(this).val());
		});		
	}
		
	function updateSelectValue(formName, time){
		$("#"+ formName).change(function () {
			// Show loading-animation
			loadingAnimation(time);
			// Display Value
		    var selectedText = $(this).find("option:selected").text();
		    $("#display-" + formName).text(selectedText);
		});		
	}	
	











